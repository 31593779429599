export const Navigation = (props) => {
  return (
    <nav className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <span>
              <img alt="" src="img/logo1.png" style={{ height: "30px" }} />
            </span>
          </a>
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li class="nav-item dropdown">
              <a
                href="#"
                className="nav-link dropdown-toggle"
                id="navbarDropdown"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                We care
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                <ul className="nav navbar-nav navbar-right">
                  <li>
                    <a class="page-scroll" href="/home">
                      Healthcare
                    </a>
                  </li>
                  <li>
                    <a class="page-scroll" href="#">
                      Human Resources
                    </a>
                  </li>
                  <li>
                    <a class="page-scroll" href="#">
                      Technologies
                    </a>
                  </li>
                  <li>
                    <a class="page-scroll" href="#">
                      Construction
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
